// Licensing: http://www.pixeden.com/icon-fonts/stroke-7-icon-font-set
//

$pe-7s-font-path: 'fonts';
$font-prefix: 'pe';

@import 'pixeden-stroke-7-icon/pe-icon-7-stroke/scss/variables';
@import 'pixeden-stroke-7-icon/pe-icon-7-stroke/scss/path';
@import 'pixeden-stroke-7-icon/pe-icon-7-stroke/scss/core';
@import 'pixeden-stroke-7-icon/pe-icon-7-stroke/scss/helper';
@import 'pixeden-stroke-7-icon/pe-icon-7-stroke/scss/icons';
